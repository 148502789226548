export type PropertyType = 'Home' | 'Land' | 'Apartment'

export const usePropertyTypeEntriesStore = defineStore(
  'propertyTypeEntries',
  () => {
    const propertyTypeEntries: Ref<DatasourceEntry[] | undefined> = ref()

    async function fetchPropertyTypeEntries() {
      return fetchDatasourceEntries('property-type', 'project-type')
        .then((propertyTypes) => {
          propertyTypeEntries.value =
            propertyTypes.entries?.data.datasource_entries
          return propertyTypeEntries.value || []
        })
        .catch((error) => {
          console.error('Error fetching property type entries:', error)
          throw createError({
            statusCode: 500,
            statusMessage: 'Error fetching property type entries',
          })
        })
    }

    return {
      propertyTypeEntries,
      fetchPropertyTypeEntries,
    }
  },
)
